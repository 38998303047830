<template>
  <div id="footer" class="footer py-4">
    <a
      href="https://github.com/ScoutsGidsenVL/groepsadmin-frontend"
      target="_blank"
      class="github-corner"
      aria-label="View source on Github"
    >
      <svg width="85" height="90" viewBox="0 0 250 250" aria-hidden="true">
        <path d="M0,0 L115,115 L130,115 L142,142 L250,250 L250,0 Z"></path>
        <path
          d="M128.3,109.0 C113.8,99.7 119.0,89.6 119.0,89.6 C122.0,82.7 120.5,78.6 120.5,78.6 C119.2,72.0 123.4,76.3 123.4,76.3 C127.3,80.9 125.5,87.3 125.5,87.3 C122.9,97.6 130.6,101.9 134.4,103.2"
          fill="currentColor"
          style="transform-origin: 130px 106px"
          class="octo-arm"
        ></path>
        <path
          d="M115.0,115.0 C114.9,115.1 118.7,116.5 119.8,115.4 L133.7,101.6 C136.9,99.2 139.9,98.4 142.2,98.6 C133.8,88.0 127.5,74.4 143.8,58.0 C148.5,53.4 154.0,51.2 159.7,51.0 C160.3,49.4 163.2,43.6 171.4,40.1 C171.4,40.1 176.1,42.5 178.8,56.2 C183.1,58.6 187.2,61.8 190.9,65.4 C194.5,69.0 197.7,73.2 200.1,77.6 C213.8,80.2 216.3,84.9 216.3,84.9 C212.7,93.1 206.9,96.0 205.4,96.6 C205.1,102.4 203.0,107.8 198.3,112.5 C181.9,128.9 168.3,122.5 157.7,114.1 C157.9,116.9 156.7,120.9 152.7,124.9 L141.0,136.5 C139.8,137.7 141.6,141.9 141.8,141.8 Z"
          fill="currentColor"
          class="octo-body"
        ></path>
      </svg>
      <div>Fork me on GitHub</div>
      <div class="github-block"></div>
    </a>
    <div class="container">
      <div class="row mb-2">
        <div class="col-12 col-lg-6">
          <div class="footer-contact-block d-flex align-items-end">
            <div class="footer-contact-logo mt-4 mt-lg-0">
              <a href="https://www.scoutsengidsenvlaanderen.be" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.7 46.8">
                  <path
                    d="M22,28.6L22,28.6l-0.2,0.2c-0.2,0-0.3,0-0.3-0.1s0-0.1,0-0.2s0.1-0.2,0.1-0.2c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0.1v0.1
	C21.9,28.5,22,28.6,22,28.6z M29.1,34.1c0.1-0.1,0.1-0.2,0.3-0.3v-2h-2.6c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0.9-0.7,1-1.5,1.1-2.6
	l0.3-4.1c0.1-1,0.1-2-0.8-2.8c-0.7-0.6-1.7-0.7-2.6-0.8c0.1,0.2,0.2,0.5,0.2,0.7c0,0.4,0,0.8-0.1,1.2l0.3,0.2l0.2,0.5
	c0,0-0.1-0.1-0.2,0v0.3l0.1,0.1h-0.2c0,0,0,0,0,0.1s0,0.1,0,0.1s0,0-0.1-0.1c0,0,0,0-0.1,0c0,0.1,0.1,0.1,0.1,0.2s0,0.1,0.1,0.2
	c0.1,0.3,0.2,0.6,0.2,0.9s0,0.6,0,1c0,0.3-0.2,0.7-0.4,1L25,27.9L24.9,29c-0.1,0.8-0.3,1-1,1h-0.3l0.1-1.4c-0.3,0.1-0.6,0.1-0.8,0
	c-0.5-0.1-1-0.2-1.4-0.6c-0.4-0.3-0.7-0.7-0.9-1.1c0,0.1-0.1,0.1-0.1,0.1l-0.2,2.2c0,0.4-0.1,0.7-0.5,0.8l-0.1,1.9h0.2
	c0.1,0,0.1,0.1,0.2,0.1v-0.1l0.6,0.1v0.4c0.2,0.1,0.5,0.3,0.7,0.4c1.2,0.6,2.6,1,3.9,1.2l-0.6-1.6c0.4,0,0.8,0,1.3,0
	c0,0,0.1,0,0.2-0.1v1.5c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0,0.7,0.1,1.2,0.1l-0.2,0.2c-0.4,0-0.5,0-0.9-0.1c0,0.2,0,0.4,0,0.6v1.3
	l1.9-1.8l-1,3.8h0.1l1-3.8l0.8,2.5l-1.5,1.5l1.5-1.3v-2.2c0-0.2,0-0.4,0-0.6C28.6,34.3,28.7,34.2,29.1,34.1z M18.5,6.7L18.5,6.7
	l0.1-0.3h-0.3l-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c0,0,0-0.1-0.1-0.2h-1.4v1.1c0,0.4,0.1,1.1-0.6,1.1
	c0,0.1,0,0.1,0,0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.7,0.2,1,0.3c0.2,0.1,0.4,0.2,0.6,0.3s0.4,0.2,0.6,0.3l0.1,0.1
	c0.1-0.1,0.2-0.1,0.3-0.2V7.6c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0V7.5c0,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0V6.9
	C18.9,7,18.8,7,18.6,7C18.7,7,18.6,6.9,18.5,6.7z M19.2,10.4l-1.6,0.1c0.3-0.1,0.6-0.2,0.9-0.3c-0.4-0.1-0.8-0.3-1.2-0.3
	c-0.6-0.1-1.2-0.2-1.7-0.3c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.1,0,0.2h-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.4,0-0.6,0-0.6
	c-0.9,0-1.9,0.1-2.8,0.3c0,0,0,0,0,0.1c0.5,0.5,1.4,0.7,2.4,0.8l0.1,3.1h0.1l0.2-0.6l0,0l0.3-0.5l-0.3,0.6c0,0.1,0,0.1,0,0.2
	L15,14.2v0.4c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3l-0.3,0.5V15l2.1-0.1l-0.1-1.6c0-0.4,0.1-0.7,0.5-0.7l0.1,2.1
	c0.3-0.4,0.5-0.8,0.7-1.3C18.9,12.4,19.2,11.5,19.2,10.4z M8.6,24.5C8.5,24.5,8.5,24.5,8.6,24.5L8.6,24.5v-0.2
	c-0.1,0-0.3,0.1-0.5,0.1c-0.3,0-0.4,0.2-0.4,0.4L8,24.9c0-0.2,0-0.3,0.2-0.2h0.1c0.1,0,0.1,0,0.2,0c-0.7,1.1-1.1,2.3-1.4,3.6
	c-0.1,0-0.1,0-0.2,0s-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0v0.1c0.3-0.1,0.5,0,0.6,0v0.1L6.8,29
	c-0.1,0.1-0.1,0.1-0.2,0.2l0.3,0.1c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.3,0,0.4s0,0.1,0,0.1l0,0c0.1,0.2,0.2,0.3,0.4,0.4
	c0.3,0.3,0.7,0.5,1,0.6c0.2-0.8,0.6-1.6,1.1-2.3c0.1-0.1,0.2-0.3,0.4-0.4v-0.1l0.3-4.9c0-0.3,0.1-0.6,0.2-0.8
	c-0.2,0.1-0.3,0.2-0.5,0.4c-0.2,0.2-0.5,0.5-0.7,0.7C8.9,24,8.8,24.3,8.6,24.5z M8.2,23.8c-0.1,0-0.2,0.1-0.3,0.2
	c-0.1,0.1-0.2,0.1-0.3,0.1l0.1,0.1c0.2,0,0.3,0,0.4,0c0,0,0.1-0.1,0.2-0.1L8.4,24v-0.1L8.2,23.8z M8,23.7l0.1-0.2
	c-0.1,0-0.1,0-0.2,0H7.8c0,0-0.1,0.1-0.1,0.2c0,0,0.1,0,0.2,0.1C7.9,23.6,8,23.6,8,23.7z M7,26.1c0,0-0.1,0.1-0.2,0.1H6.7
	c-0.1,0-0.1,0-0.2,0v0.2c0.1,0,0.1,0,0.2,0s0.3,0,0.3,0l0.3-0.2L7.2,26C7.1,26,7,26,7,26.1z M6.7,24.6c0.1,0,0.2,0,0.3-0.2l-0.1-0.1
	l-0.3,0.2H6.5v0.2C6.6,24.7,6.6,24.7,6.7,24.6z M6.1,32.2c0.1,0.1,0.1,0.1,0.2,0.2L5.9,32c-0.1,0-0.1,0-0.2,0
	c-0.1,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.1,0H5.2l-0.3-0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1
	s0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1l-0.2-0.3c-0.1,0-0.1,0-0.1,0.1s0,0.1,0.1,0.1H4.5c0.7,0.8,1.4,1.4,2.2,1.8l0,0
	c0.1-0.1,0.2-0.1,0.4-0.1c0.4,0,0.5,0.2,0.6,0.5c0.5,0.1,1.1,0.2,1.6,0.2l-0.1,0.1c-0.3,0-0.6,0-0.9-0.1c-0.2,0-0.4-0.1-0.6-0.2
	c0,0.2,0.1,0.4,0.1,0.6v0.2h3.3v-0.8c0,0,0,0-0.1,0c-0.2,0.1-0.5,0.1-0.7,0.1s-0.5,0.1-0.7,0.1c0,0,0.1,0,0.1-0.1l0,0
	c0.5,0,1-0.1,1.5-0.2v-1.7c-0.3-0.2-0.6-0.4-1-0.5l0,0c0,0.1,0,0.1,0.1,0.1c-0.1,0-0.1,0-0.1,0s-0.1,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1
	H10c0,0,0,0-0.1,0.1c0,0,0,0.1,0.1,0.1c-0.1-0.1-0.2-0.1-0.2-0.1H9.7c0,0,0,0-0.1,0c0,0-0.1,0-0.1-0.1H9.3c0-0.1,0-0.1-0.1-0.1
	s-0.1,0-0.2-0.1v-0.1c0,0-0.1,0-0.1-0.1L8.8,32v-0.2c-0.2,0-0.3-0.1-0.5-0.1H8.1c-0.1,0-0.1,0.1,0.1,0.2c-0.1,0-0.1,0-0.1-0.1
	c0,0,0-0.1-0.1-0.1c-0.2-0.1-0.4-0.1-0.6,0s-0.4,0.1-0.6,0l-0.1,0.1c0,0.1,0,0.1-0.1,0.2L7,32.2l0,0l0.3,0.2l-0.4-0.2
	c-0.1,0-0.1,0-0.1,0l-0.6-0.4C6,31.9,6,32,6.1,32.2z M4,32.4C4,32.5,4,32.5,4,32.4C3.9,32.5,3.9,32.5,4,32.4
	c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0.4-0.2,0.9-0.1,1.3c0.1,0.5,0.3,1,0.6,1.4c0.5,0.7,1.1,1.3,1.9,1.6s1.6,0.4,2.5,0.4
	c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1l0,0l0,0v0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3-0.1l-0.1-0.2
	C10.1,37,10.1,37,10,37H9.9c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.7-0.4-1.8-0.7-2.6-1c-0.3-0.1-0.8-0.2-1-0.4
	S6.3,35,6.3,34.8s0.1-0.4,0.2-0.6c-0.4-0.2-0.9-0.5-1.2-0.8c-0.4-0.3-0.7-0.7-1.1-1.1L4,32.4C4,32.3,4,32.3,4,32.4
	C4.1,32.4,4.1,32.5,4,32.4C4.1,32.4,4.1,32.4,4,32.4L4,32.4z M3.6,33.8c0-0.4,0-0.7,0-1.1c-0.5,0.5-0.7,1.1-0.7,2.1V35
	c0,2.4,1.2,2.9,3.3,3.5c1,0.2,1.7,0.3,1.7,1.5c0,0.4-0.2,0.9-0.7,0.9c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.2-0.1-0.7-0.1-1.4H3.1v3
	c1.1,0.9,2.6,1.2,4.1,1.2c3.3,0,4.3-1.6,4.3-3.8v-0.1c0-0.5-0.1-1.1-0.3-1.5c0-0.1-0.1-0.2-0.2-0.3l0,0c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.1-0.1-0.2,0v0.1h0.2l0.1,0.1l-0.6,0.1c-0.1-0.1-0.1-0.1-0.1-0.1s0,0-0.1,0l0,0v0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
	c-1.1,0-2.1-0.3-3.1-0.7s-1.9-1.1-2.5-2.1C3.9,34.7,3.7,34.3,3.6,33.8z M9.7,37.4L9.7,37.4c0.2,0.1,0.2,0.1,0.3,0.1s0.1,0,0.2,0.1
	v-0.1c-0.1-0.1-0.1-0.1-0.2-0.1H9.9C9.8,37.3,9.7,37.3,9.7,37.4L9.7,37.4z M9.8,37.6L9.8,37.6C9.7,37.7,9.8,37.7,9.8,37.6
	c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0,0.1-0.1c0,0,0-0.1-0.1-0.1C9.8,37.7,9.8,37.6,9.8,37.6z M6.4,26.8v0.3c0.1,0,0.1-0.1,0.2-0.1
	c0.1-0.1,0.4-0.1,0.5-0.1c0-0.1,0-0.1,0-0.2L7,26.6c-0.2,0.1-0.3,0.2-0.4,0.2C6.6,26.8,6.5,26.8,6.4,26.8z M6.6,27.9
	C6.5,28,6.4,28,6.3,28.1c0,0.1,0,0.2,0,0.2c0.2,0,0.3,0,0.5-0.1c0,0,0.1-0.1,0.2-0.1C7.1,28,7.1,28,7.1,27.9v-0.1
	c-0.1,0-0.2,0-0.2,0C6.8,27.9,6.7,27.9,6.6,27.9z M7.1,25.5v0.1l0,0h0.1l0.1-0.1v-0.1L7.1,25.5C7.2,25.5,7.2,25.5,7.1,25.5z
	 M12.3,30.8L12.3,30.8c0.1,0,0.2,0,0.2,0C12.4,30.8,12.4,30.8,12.3,30.8z M10.4,29.2c-0.4,0-0.6-0.3-0.6-0.5
	c-0.1,0.1-0.3,0.3-0.4,0.4c-0.5,0.7-0.8,1.4-1,2.1c0.5,0.2,1.1,0.3,1.7,0.3c0.9,0.1,2,0,2.9-0.3v-0.1C12.9,31,12.9,31,12.8,31
	c0.1-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1c-0.1,0-0.1,0-0.1,0l0.3-0.3v-0.1c0,0-0.1-0.1-0.2-0.1c0.2,0,0.3,0,0.4-0.1
	c0,0,0.1-0.1,0-0.1V30c0,0,0.1,0,0.3,0l0.2-0.2c0.1,0.1,0.1,0.1,0.3,0.1l0.3-4.4c-1.1,0.4-2.1,0.9-3.1,1.6v-0.2
	c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.3,1-0.5,1.4-0.6c0.1,0,0.2,0,0.2-0.1l-3.8-0.3l0.2,2.2H11c0.1-0.1,0.1-0.1,0.2-0.2
	c0,0,0,0.1-0.1,0.2l0,0l0,0l0,0v0.5C11.1,28.3,11.1,29.2,10.4,29.2z M12,34.9v5c0,0.4,0,0.8-0.4,1.1v2H20v-3.6
	c-0.3-0.2-0.6-0.3-0.9-0.5h-2.3v0.4c0,0.9,0,1.3-1,1.3h-0.2v-2.3h2.2v-0.4c-0.6-0.5-1.2-1.1-1.7-1.8h-0.5v-0.7
	c-0.1-0.2-0.3-0.4-0.4-0.6c-0.6-1-1-2-1.2-3.1l0,0h-2.4v2C12,34.1,12,34.5,12,34.9z M19.8,39L19.8,39L19.8,39L19.8,39z M17.5,37.7
	v-1.4h-1.4C16.6,36.8,17,37.3,17.5,37.7z M13.6,21.2c-1.1,0.3-2.1,0.8-3,1.4c0.1,0,0.2-0.1,0.3-0.1c0.8,0.1,0.6,1,0.6,1.5v0.2
	l3.3,0.2l0.2-2.8c-0.3-0.2-0.6-0.4-0.9-0.6C13.8,21.1,13.7,21.1,13.6,21.2z M14.7,29.7l-0.1,1.9l2.9,0.2H14c0,0.1,0,0.2,0,0.2
	c0.3,1.1,0.7,2.2,1.4,3.1v-1h0.2c0.9,0,1,0.3,1,1.1v0.3h3.2v-3.4c-0.2-0.1-0.4-0.3-0.7-0.4h-0.4l0.1-1.9c-0.4-0.3-0.3-0.7-0.3-1.1
	l0.3-4.9c0-0.4,0-0.8,0.4-1l0.1-1.9l-3.9-0.2l-0.1,1.9c0.4,0.3,0.3,0.7,0.3,1.1l-0.3,4.9C15.1,29.1,15.1,29.5,14.7,29.7z M18.1,18.3
	c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.1-0.2,0.2l0.1,1.7l3.7-0.1l-0.1-1.7c-0.4-0.2-0.3-0.6-0.4-0.9v-0.7c-0.3,0.2-0.5,0.5-0.9,0.7
	C19.5,17.8,18.8,18.1,18.1,18.3z M22.6,21.6c-0.4-0.2-0.9-0.4-1.4-0.5l-1-0.1l-0.1,1.7l0,0l0,0l0,0c0,0,0.1,0,0.1,0.1h0.1
	c0,0.1,0,0.1,0.1,0.1s0.1,0,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1l0.1,0.2v0.3l0.7-0.1c0.1,0,0.1-0.1,0-0.1c0,0,0.1,0,0.1,0.1
	c0,0,0,0,0,0.1c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.4,0,0.5,0.1l0.1-0.1c0,0,0-0.1,0.1-0.1l-0.3-0.2l0,0l-0.3-0.2l0.3,0.2h0.1l0.5,0.4
	c0,0,0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2l0.3,0.4c0.1,0,0.1,0,0.1,0l0.1-0.1h0.1h0.2l0.2,0.2c0,0,0,0,0.1,0c0,0,0,0,0.1,0v-0.1h0.1
	c0,0,0,0,0.1,0c0-0.1,0-0.1-0.1-0.1s-0.1-0.1-0.1-0.1l0.2,0.1h0.1v-0.1l0,0C23.8,22.5,23.2,22,22.6,21.6z M23.1,24.6
	C23.1,24.5,23.1,24.5,23.1,24.6c0-0.1,0-0.1-0.1-0.2h-0.1c0,0.1-0.1,0.1-0.1,0.2l-0.7,0.3c0,0,0,0,0.1-0.1c0,0,0,0,0.1,0v-0.1l0,0
	c0,0,0,0,0.1,0l0.2-0.1c0,0,0-0.1,0-0.2h-0.1c0,0,0,0-0.1,0l-0.8-0.1c0,0,0,0-0.1,0h-0.1l-0.2,0.1c-0.1,0-0.3,0-0.4,0
	s-0.2,0-0.3,0.1l-0.1,0.9c0.5,0,1,0,1.6-0.1s1.1-0.3,1.7-0.6c0.1,0,0.2-0.1,0.2-0.2v-0.1h-0.1c-0.1,0-0.1-0.1-0.2,0c0,0-0.1,0-0.2,0
	c0,0,0,0,0-0.1h-0.1C23.2,24.5,23.2,24.5,23.1,24.6z M23.4,26.1L23.4,26.1L23.4,26.1C23.3,26.2,23.3,26.2,23.4,26.1
	C23.3,26.1,23.3,26.1,23.4,26.1C23.3,26.1,23.3,26.1,23.4,26.1L23.4,26.1z M22.9,27.4L22.9,27.4L22.9,27.4l-0.2,0.1l-0.1-0.2
	c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0.1V27.4z M22.4,26.5l-0.1,0.1h-0.2c0,0,0-0.1-0.1-0.1l0.1-0.2c0,0,0,0,0.1,0
	C22.4,26.3,22.4,26.4,22.4,26.5z M21.9,26.5L21.9,26.5c-0.1,0.1-0.1,0.1-0.1,0.1l-0.4,0.1l0,0l0,0l0,0l0,0l0,0l0.3-0.4h0.2V26.5z
	 M23.5,28.4l0.2-3.4l0,0c-0.2,0.2-0.5,0.3-0.8,0.5s-0.8,0.3-1.3,0.4c-0.5,0-0.9,0-1.3,0v0.3v0.1c0.1,0.4,0.3,0.7,0.6,1
	s0.6,0.6,0.9,0.8c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1C23.3,28.5,23.4,28.5,23.5,28.4z M24.9,21.4l-3.3-0.3
	c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.2,0.7,0.5,1,0.7c0.3,0.3,0.6,0.6,0.8,1h0.1h0.1c0,0,0-0.1,0,0l0,0l0,0l0,0
	l0,0c0,0,0,0,0.1,0c0.2-0.4,0.2-0.8,0.2-1.2C25.1,21.8,25,21.6,24.9,21.4L24.9,21.4z M25,24.6c0,0.1,0,0.1,0,0.2v0.6l0,0l0,0l0,0
	l0,0c0,0,0,0-0.1,0l-0.1,2.3c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.5,0.3-1,0.3-1.6C25.3,25.4,25.2,25,25,24.6z M26.4,16.6l-3,0.1v1.2
	c0,0,0,0,0.1,0s0.2,0,0.3,0.1l0.2-0.6l0,0l0.3-0.5L24,17.5c0,0.1,0,0.1,0,0.2l-0.6,1v0.1c0.1,0.1,0.2,0.1,0.4-0.1
	c0.1-0.1,0.2-0.2,0.3-0.3l-0.5,0.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.5,0.3,1,0.5,1.6,0.6c0.6-0.7,1.1-1.3,1.4-2
	c-0.1-0.1-0.2-0.1-0.3-0.2C26.5,17.6,26.5,17.1,26.4,16.6z M23.5,38.3L23.5,38.3v-0.6l-0.4-0.1c-0.1,0-0.2,0-0.3-0.1l-0.1,0.3
	c0,0,0,0,0.1,0.1l-0.1,0.2h-0.1v0.1l0.6,0.1l-0.2,1l-2.2-0.6l0.2-0.9l0.6,0.1v-0.1h-0.1l0.1-0.2h0.1l0.1-0.3c-0.1,0-0.2,0-0.3-0.1
	l-0.3-0.1L21,37.7h-0.2v2c0.9,0.3,1.8,0.5,2.7,0.6v-0.1V38.3z M23.3,37.5l0.4,0.1l-0.1,0.5v0.1l0.6,0.2l0.5-2l-0.6-0.2l-0.2,0.6
	l-0.4-0.1c0,0.1-0.2,0.3-0.2,0.3S23.4,37.3,23.3,37.5z M21.8,35.3l0.6,0.2l-0.1,0.4c0.2,0,0.4,0.2,0.4,0.2s0.2-0.1,0.4,0l0.1-0.4
	l0.6,0.1l0.2-0.6l-2-0.5L21.8,35.3z M22.6,38l-0.8-0.2l-0.1,0.3l-0.6-0.2l-0.2,0.7l2,0.5l0.2-0.6l-0.6-0.1L22.6,38z M21.9,37.5
	c0.2-0.1,0.2-0.3,0.2-0.3s-0.1,0.1-0.2,0.1V37.5z M22.6,37.2c0,0-0.1,0.2,0.1,0.4l0.1-0.3C22.7,37.3,22.6,37.2,22.6,37.2z
	 M20.8,37.5L20.8,37.5l0.2-0.6l0.4,0.1c0-0.2,0.2-0.4,0.2-0.4s-0.1-0.2,0-0.4l-0.4-0.1l0.2-0.6l-0.6-0.2
	C20.8,35.3,20.8,37.5,20.8,37.5z M20.8,35.3l0.8,0.2l-0.1,0.6l0.3,0.1c0.1-0.1,0.4,0,0.4,0s-0.1-0.2,0.1-0.3l0.1-0.3l-0.6-0.1
	l0.2-0.9l2.2,0.6l-0.2,1l-0.6-0.1l-0.1,0.3c0.1,0.1,0,0.3,0,0.3s0.2,0,0.3,0.1l0.3,0.1l0.1-0.6l0.9,0.2l-0.6,2.2l-0.7-0.2l0.7,1.7
	l0,0l0.6,1.6l0,0l0.6,1.4h3.4v-1.1l0,0v-2.8l-0.4,0.4l-0.8-0.8L27.6,39l0.2,0.1l-0.2,0.5l-0.4-0.1l0.5,1.2l-0.5-0.1L27,40.2V41
	l-0.7,0.6L26,40.8l0.3-0.7L26,40.3l-0.6-0.1l1-0.8L26,39.3l0.1-0.6l0.3,0.1v-0.2l-1.1,0.3l-0.5-1.7l1-1L25,34.1c0,0,0,0-0.1,0
	c-0.9-0.1-1.6-0.3-2.2-0.5s-1.2-0.4-1.8-0.7c-0.2-0.1-0.5-0.3-0.7-0.4v1.2c0.4,0.3,0.4,0.7,0.4,1.1v0.5
	C20.6,35.3,20.8,35.3,20.8,35.3z M29,38.2v0.2l-0.2-0.2l-0.2,0.1l0.1-0.2l-0.1-0.2l0.2,0.1l0.1-0.2v0.3l0,0v-0.6l-1.7,1.4V39h0.1
	l0.3-0.3l0.8,0.8l0.5-0.6v-0.2l0,0L29,38.2L29,38.2z M28.2,35l-0.8,3.3h0.1l1.4-1.4L28.2,35z M26,40.1l0.6-0.5l0.1-0.2L26,40.1
	L26,40.1z M26.9,40l-0.3-0.1l-0.4,0.9l0.2,0.6l0.5-0.4C26.9,41,26.9,40,26.9,40z M25.7,37.4l-0.2,0.1l0.1-0.2l-0.2-0.2l0.2,0.1
	l0.1-0.2v0.3l0.2,0.1L25.7,37.4v0.3V37.4z M25.4,38.7l1.1-0.3l0.1,0.4h0.1v-0.1l-0.9-2.3L25,37.2L25.4,38.7z M26.8,38.3l-0.6-1.9
	v0.4L26.8,38.3z M27.8,35l-1.5,1.4l0.6,1.9H27L27.8,35z M27.3,40.5h0.2l-0.4-1L27,39.8L27.3,40.5z M27.4,43.8h-0.6v3h0.7v-1.5
	l0.9,1.5H29v-3h-0.7v1.5L27.4,43.8z M26.2,43.8h-2v3h2v-0.6h-1.3v-0.5H26v-0.6h-1.1v-0.5h1.3C26.2,44.6,26.2,43.8,26.2,43.8z
	 M20.4,43.1h3.5v-2L23.8,41c-1-0.3-2-0.6-2.9-1l0,0c0,0.4,0,0.8-0.4,1.1L20.4,43.1L20.4,43.1z M22.6,44.4c0.2,0,0.3,0.1,0.3,0.3
	S22.8,45,22.6,45h-0.4v-0.6H22.6z M22.7,43.8h-1.2v3h0.7v-1.1h0.2l0.5,1.1h0.8l-0.6-1.2c0.2-0.1,0.5-0.4,0.5-0.8
	C23.7,44.2,23.3,43.8,22.7,43.8z M21,43.8h-2v3h2v-0.6h-1.3v-0.5h1.1v-0.6h-1.1v-0.5H21V43.8z M17.3,44.4c0.2,0,0.3,0.1,0.3,0.2
	c0.1,0.1,0.1,0.2,0.1,0.7s0,0.6-0.1,0.7c-0.1,0.1-0.2,0.2-0.3,0.2H17v-1.7h0.3V44.4z M17.4,43.8h-1.1v3h1.1c0.3,0,0.6-0.1,0.8-0.3
	c0.3-0.3,0.3-0.7,0.3-1.2s0-0.8-0.3-1.2C18,43.8,17.7,43.8,17.4,43.8z M14,43.8h-0.6v3h0.7v-1.5l0.9,1.5h0.6v-3h-0.7v1.5L14,43.8z
	 M11.5,45.7l0.3-0.8l0.2,0.8H11.5z M11.5,43.8l-1.1,3l0,0h0.8l0.1-0.4h0.9l0.1,0.4h0.8l-1.1-3H11.5z M8.8,45.7l0.3-0.8l0.2,0.8H8.8z
	 M8.7,43.8l-1.1,3h0.8l0.1-0.4h0.9l0.2,0.3h0.8l0,0l-1.1-3L8.7,43.8L8.7,43.8z M6.3,43.8H5.6v3h2v-0.6H6.4L6.3,43.8L6.3,43.8z
	 M3.5,43.8H2.7l1,3h0.5l1-3H4.4l-0.5,1.7L3.5,43.8z M2.6,26.2L2.6,26.2c0,0.1,0,0.1,0,0.1C2.7,26.3,2.7,26.2,2.6,26.2L2.6,26.2z
	 M2.4,26.9L2.4,26.9V27V26.9z M2.3,26.7v0.1c0,0,0,0,0.1,0L2.3,26.7z M3.4,25L3.4,25L3.4,25L3.4,25C3.5,25,3.5,25,3.4,25L3.4,25
	C3.5,25,3.5,25,3.4,25z M3.8,18.8c-0.6,0-0.6-0.7-0.6-0.7l-0.1-4.4c0-0.1-0.1-0.7,0.6-0.8c0.6,0,0.6,0.7,0.6,0.7L4.5,18
	C4.4,18.1,4.5,18.8,3.8,18.8z M3.6,23.8c0.1,0.3,0.3,0.6,0.5,0.9C4,24.8,3.8,24.9,3.6,25l0,0c0.2-0.1,0.3-0.1,0.5-0.3l0,0
	c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.4-0.6-0.8C3.2,24.2,3.3,23.9,3.6,23.8z M3.6,26.2
	L3.6,26.2L3.6,26.2L3.6,26.2L3.6,26.2c0,0.1,0.1,0.1,0.1,0.1v0.1l0,0H3.6v-0.1V26.2z M4.2,22.3c-0.1-0.2-0.3-0.5-0.3-0.7
	s0.1-0.3,0.2-0.4l0.3,0.4c0.1,0.1,0.1,0.2,0.1,0.3C4.4,22.1,4.3,22.3,4.2,22.3z M4.6,21.9c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.1-0.2-0.3-0.3-0.4l0.1-0.1c0.2,0,0.3,0.2,0.3,0.4C4.6,21.7,4.6,21.8,4.6,21.9z M4.6,23.3c0.1-0.1,0.1-0.2,0.2-0.3l0,0h0.1
	c0,0,0,0,0-0.1c0.3-0.4,0.5-0.8,0.5-1.2c0-0.5-0.3-0.8-0.6-1c0.2,0,0.8-0.1,1-0.2c0.8,0.8,0.7,1.7,0.7,2.2c0,0.1,0,0.1,0,0.2H5.3
	v0.5h0.2c0.1,0,0.2,0.1,0.2,0.3c0,0.1,0,0.3-0.2,0.4l-0.2,0.2c-0.1-0.2-0.3-0.5-0.4-0.7v-0.1c0-0.1,0-0.3,0-0.4l0,0l0,0
	c0,0.1-0.1,0.3-0.1,0.4C4.7,23.4,4.6,23.3,4.6,23.3z M5.7,24.6C5.7,24.6,5.7,24.5,5.7,24.6C5.7,24.5,5.7,24.6,5.7,24.6L5.7,24.6z
	 M5.9,24.5L5.9,24.5c0.1,0,0.1,0,0.1,0.1s0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2H6.1C6,24.9,6,24.9,6,24.8C5.9,24.6,5.9,24.6,5.9,24.5
	L5.9,24.5z M6.1,24.6v-0.1c-0.1,0-0.1,0-0.1-0.1v-0.1c0,0,0-0.1-0.1-0.1v-0.1H6c0,0.1,0,0.1,0,0.1l0.1,0.1v0.1l0.1,0.1L6.1,24.6
	C6.2,24.7,6.2,24.6,6.1,24.6L6.1,24.6z M6.2,24.7L6.2,24.7C6.3,24.7,6.3,24.7,6.2,24.7C6.3,24.7,6.3,24.8,6.2,24.7L6.2,24.7z
	 M6.3,24.4C6.2,24.3,6.2,24.3,6.3,24.4C6.2,24.3,6.2,24.3,6.3,24.4c-0.1-0.1-0.1-0.1-0.1-0.1s0,0,0-0.1c0,0,0,0-0.1-0.1L6,24v-0.1
	h0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0c0,0,0,0.1,0.1,0.1C6.2,24.2,6.2,24.3,6.3,24.4C6.3,24.3,6.3,24.3,6.3,24.4z M6.3,23.8
	L6.3,23.8L6.3,23.8L6.3,23.8L6.3,23.8L6.3,23.8L6.3,23.8L6.3,23.8z M6.3,23.5c0,0,0.1,0,0.1-0.1L6.3,23.5L6.3,23.5z M6.4,24.1
	C6.4,24.1,6.3,24,6.4,24.1C6.3,24,6.3,24,6.3,24l0,0c0.1,0,0.1,0,0.1,0S6.4,24,6.4,24.1C6.4,24,6.4,24.1,6.4,24.1L6.4,24.1z
	 M6.5,23.9L6.5,23.9C6.5,23.8,6.6,23.8,6.5,23.9C6.6,23.9,6.6,23.9,6.5,23.9L6.6,24C6.6,24,6.6,24,6.5,23.9
	C6.5,24,6.5,23.9,6.5,23.9z M6.6,23.4L6.6,23.4L6.6,23.4L6.6,23.4v-0.1l0,0C6.6,23.4,6.6,23.4,6.6,23.4z M4.4,10.8
	c-0.3,0-0.6,0-0.9,0c-2.1,0.1-3.6,0.7-3.5,3.1L0.1,18c0.1,2.4,1.1,2.9,3.1,2.9c-0.2,0.2-0.3,0.5-0.3,0.9s0.1,0.7,0.2,1.1
	C2.6,23.2,2,23.7,2,24.6c0,0.7,0.3,1.4,1.1,1.6c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.1-0.1,0.3-0.1,0.6c0,0.2,0.1,0.4,0.1,0.5
	c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.4s0.4,0.2,0.7,0.2c0.4,0,0.8,0,1.2-0.2c0.3-0.2,0.6-0.5,0.7-0.8
	c0.1-0.2,0.1-0.3,0.1-0.5S6.1,27.3,6,27.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2-0.1-0.4-0.1H5.2v0.1c0.1,0,0.2,0.1,0.3,0.1
	c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.7,0.3-1,0.2
	c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.4-0.4-0.5-0.6s-0.1-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.5s0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.2
	l0,0l0,0l0,0l0,0c0,0,0,0-0.1-0.1v-0.1H4v0.1v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0.1-0.1l0,0L4,26.2l0,0h0.1
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1v-0.1c0,0,0,0,0.1,0v0.1l0.1-0.1l0,0l0,0l0,0l0,0c0,0,0,0,0.1,0
	v0.1l0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0-0.1l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0h0.1c0.3,0.3,0.7,0.4,1,0.4s0.6-0.1,0.7-0.4
	c0,0,0.1-0.1,0.2-0.1l0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.4v-0.3H6.5v0.3c0,0.2-0.1,0.3-0.3,0.3
	c-0.1,0-0.3-0.2-0.4-0.4v-0.1l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2h0.1v-0.1v-0.1c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1V25c0,0-0.1,0-0.1-0.1
	c0,0,0,0,0-0.1c0.1,0,0.1,0.1,0.1,0.1V25v0.1L6,25.2l0.1,0.1c0.1-0.1,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.3l0.5-0.5c0.1,0,0.2,0,0.2,0
	s0,0,0.1-0.1l0,0l-0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2s0.1-0.1,0.2-0.2c1.5-1.1,3.2-1.8,5-2.2c0.2-0.1,0.5-0.1,0.8-0.1
	c-0.3-0.2-0.7-0.3-1-0.4c0.4-0.1,0.8-0.2,1.2-0.4c-1.3-0.1-2.5-0.4-3.7-0.9c-0.7-0.3-1.4-0.6-2-1.1c0.1,0.7,0.3,1.3,1,1.8
	c0.1,0.1,0.3,0.1,0.5,0.2C9,20.5,8.4,20.6,7.9,21c-0.6,0.4-0.9,1.1-1,1.7c-0.1-0.3-0.1-1.4-0.8-2c1-0.4,1.6-1.2,1.5-2.8v-0.5
	c-0.4-0.4-0.8-0.8-1.1-1.3c-0.4-0.6-0.7-1.3-0.9-2c-0.2-0.7-0.2-1.5,0-2.2l-0.5-0.2L4.4,10.8z M10.8,13.4c0-0.4-0.1-0.7,0.3-1
	L11,10.7h-0.7c-0.1,0-0.2,0-0.3,0H9.6c0,0,0,0.1-0.1,0.1c0,0.1,0,0.2-0.1,0.3l0.6,0.2l0,0l0.6,0.4L10,11.4c-0.1,0-0.1,0-0.2,0
	l-1-0.6c-0.1,0-0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3l-0.7-0.6c-0.1,0-0.2,0-0.3,0.1S8,11.1,7.9,11.2c-0.1,0-0.2,0-0.2,0
	l-0.3,0.1l-0.1-0.1l0.1,1.4c0.2,0.1,0.3,0.3,0.3,0.5c0.7,0.6,1.5,1.1,2.2,1.4c0.3,0.1,0.6,0.2,0.9,0.3V13.4z M6,11.8
	C6,11.9,6,11.9,6,11.8c-0.1,0-0.1,0-0.2,0H5.7c-0.2,0.7-0.3,1.5,0,2.3c0.2,0.8,0.5,1.5,1,2.1c0.2,0.3,0.5,0.6,0.7,0.8l-0.1-3.3
	c0-0.8-0.2-1.4-0.6-1.9l-0.1-0.1c0,0-0.1,0-0.2,0s-0.2,0-0.3,0C6.1,11.8,6.1,11.8,6,11.8C6.1,11.8,6.1,11.8,6,11.8L6,11.8z M5.6,9.3
	c0-0.6,0-1.3,0-1.9c0-0.2,0.1-0.5,0.1-0.7H4v2.7c0.5,0.4,1.2,0.7,1.8,0.9C5.8,9.9,5.7,9.6,5.6,9.3z M11.4,3.5V1.1
	C10.3,0.3,9.1,0,7.7,0C6.8,0,5.8,0.1,5.1,0.6C4.3,1.2,4.1,1.8,4,2.8V3c0,1.8,0.7,2.5,2.1,2.9c0.1-0.1,0.1-0.3,0.2-0.4
	c0.4-0.6,0.9-1.1,1.5-1.4C8,4,8.2,3.9,8.4,3.8H8.3C8,3.7,7.6,3.6,7.4,3.4C7.2,3.2,7.2,3,7.2,2.8c0-0.4,0.2-0.7,0.6-0.7
	c0.6,0,0.6,0.6,0.6,1.1v0.2L11.4,3.5z M10.8,6.1c0,0.1-0.2,0.2-0.4,0.2c0,0-0.1,0-0.1-0.1V5.9V5.8c0,0,0,0,0.1-0.1h0.1L10.8,6.1z
	 M9.6,8.4C9.5,8.5,9.5,8.5,9.5,8.5c-0.1,0-0.1,0-0.1-0.1l0,0l0,0C9.5,8.3,9.5,8.3,9.6,8.4C9.6,8.3,9.6,8.3,9.6,8.4z M7.2,9
	c0,0.1-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1V8.9c0,0,0.1-0.1,0.3-0.1C7.1,8.8,7.2,8.8,7.2,9z M6.8,9.3L7,9.5
	c0,0.2-0.4,0.4-0.5,0.4S6.2,9.9,6,9.8l0,0c0,0,0,0,0-0.1l0,0c0.1,0,0.5-0.2,0.6-0.3C6.5,9.4,6.6,9.3,6.8,9.3z M11.6,7.2L11.6,7.2
	c0-0.5-0.1-1-0.2-1.4c-0.1-0.4-0.4-0.7-0.8-1c-0.5-0.4-1.3-0.6-2-0.8C8.2,4.1,7.8,4.2,7.5,4.4C6.9,4.8,6.4,5.3,6.1,6l0,0
	c0.2,0.1,0.5,0.2,0.8,0.2c0.9,0.2,1.5,0.3,1.5,1.4c0,0.4-0.2,0.8-0.6,0.8c-0.2,0-0.5-0.1-0.6-0.3S7.1,7.4,7.1,6.8H5.8
	C5.6,7.5,5.5,8.3,5.7,9.2C5.8,9.6,5.8,10,6,10.4c0.6,0.1,1.1,0.2,1.7,0.2c0.4,0,0.8,0,1.1-0.1c0.5-0.4,0.9-0.7,1.4-0.9
	c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.3,0.3-0.7,0.4-1.2c-0.2,0-0.3-0.2-0.3-0.5l0.2-0.2h0.1C11.6,7.3,11.6,7.3,11.6,7.2z M11.9,10.8
	c-0.1,0-0.1,0-0.2-0.1l0,0h-0.1l0.1,1.7c0.4,0.2,0.4,0.6,0.4,0.9l0.1,1.7c0.7,0.1,1.4,0.2,2.1,0.1l-0.1,0.1c-0.5,0-1,0-1.5-0.1
	c-0.2,0-0.4-0.1-0.5-0.1l0.1,2.6c0,0.2,0,0.7-0.6,0.7s-0.6-0.5-0.6-0.7l-0.1-3c-0.3-0.1-0.6-0.2-0.9-0.3c-0.7-0.3-1.5-0.7-2-1.2
	C8,13.1,8,13,7.9,13c0,0.1,0,0.2,0,0.3L8,17.2c0,0.2,0,0.3,0,0.5c0.5,0.3,0.9,0.6,1.5,0.8c1.3,0.4,2.6,0.6,4,0.5
	c0.3-0.1,0.6-0.1,0.9-0.2c0.1,0,0.3-0.1,0.5-0.1c0.2-0.5,0.2-1,0.2-1.6L15,15.2c-0.1,0-0.3,0-0.4,0c0,0,0.1,0,0.1-0.1l0,0
	c0.1,0,0.2,0,0.3-0.1l-0.1-1.9c0-0.4,0-0.8,0.2-0.9v-0.7h-0.2c0,0,0,0-0.1,0.1c0,0,0,0.1,0.1,0.2c-0.1-0.1-0.3-0.2-0.4-0.2h-0.1
	c0,0,0,0-0.1,0c0,0-0.1,0-0.2-0.1l-0.3,0.1c0-0.1,0-0.2-0.2-0.2c-0.1,0-0.2,0-0.3-0.1v-0.1c0,0-0.1,0-0.1-0.1l-0.1-0.3v-0.3h-1.2
	C11.8,10.7,11.8,10.8,11.9,10.8z M13.1,7.2L13.1,7.2L12.6,8l-0.4-0.1l-0.3-0.4V7.4c0,0,0.1-0.1,0.1-0.2l0.8-0.3c0,0,0,0,0.1,0
	C13,7.1,13,7.1,13.1,7.2L13.1,7.2L13.1,7.2z M13.7,6c-0.5-0.5-1.1-1-1.8-1.3v2.2c0,0.7,0,1.4,0.2,2c0.2,0,0.3-0.1,0.5-0.1
	c1-0.1,1.9-0.1,2.8,0c0-0.1,0-0.2-0.1-0.3S15.1,8.2,15.1,8c0,0,0,0,0-0.1C14.7,7.2,14.3,6.5,13.7,6z M19.3,4.2V1.4
	c-1-0.7-2.3-1.1-3.5-1.2s-2.3,0.2-3,0.7S12,2.2,11.9,3c0.3,0,0.5,0,0.6,0.2c0,0.1,0.1,0.3,0,0.4c0,0.2-0.1,0.3-0.2,0.4
	c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.1,0l0,0c0.3,0.1,0.7,0.3,1,0.5c0.8,0.6,1.5,1.2,2,2.1c0-0.1,0.1-0.1,0.1-0.2L15,3.3
	c0-0.4-0.1-1.1,0.6-1.1s0.6,0.7,0.6,1.1v0.6L19.3,4.2z M22.8,13.9c0.4-1.2,0.6-2.4,0.5-3.6l-3.9,0.1c0,0.1,0,0.2,0,0.3
	c-0.1,0.6-0.2,1-0.3,1.4s-0.2,0.8-0.4,1.2c-0.2,0.5-0.5,1-0.8,1.5l0.1,2.7c0,0.2,0,0.5-0.1,0.7c0.1,0,0.2,0,0.3-0.1
	c0.8-0.2,1.5-0.5,2.1-1c0.2-0.2,0.4-0.3,0.6-0.5l-0.1-4.1c0.4,0,0.5,0.2,0.6,0.7l0.1,1.6h1C22.5,14.4,22.7,14.2,22.8,13.9z
	 M24.5,11.9L24.5,11.9L24.5,11.9L24.5,11.9L24.5,11.9z M28,13.3l2.3-0.1l-0.1-2.3c-0.9-0.6-1.8-0.8-2.8-0.9c0.1,0.2,0.2,0.3,0.2,0.5
	c0.1,0.4,0.2,0.8,0.3,1.2s0.1,0.8,0.1,1.2c0,0,0-0.1-0.1-0.1l0,0c-0.1-0.8-0.2-1.6-0.5-2.4c0-0.2-0.1-0.3-0.2-0.4
	c-0.2,0-0.4,0-0.7,0c-0.9,0-1.8,0.2-2.5,0.7c-0.3,0.2-0.4,0.4-0.6,0.6l0,0c0,0.1,0.1,0.3,0.2,0.3v0.1c0,0,0,0,0,0.1l0,0
	c0,0-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.4-0.1,0.6l0,0c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.5-0.4,0.9-0.6,1.3h0.9v-0.1l-0.1-0.1h0.1v0.1
	c0.4,0.9,1.3,1.2,2.7,1.5c0.5,0.1,1,0.2,1.3,0.5c0.3-0.9,0.4-1.8,0.4-2.7c-0.2,0-0.4-0.1-0.5-0.1c-0.3-0.1-0.7-0.2-0.9-0.4
	s-0.2-0.3-0.2-0.5c0-0.4,0.2-0.7,0.6-0.7c0.6,0,0.6,0.6,0.6,1v0.2L28,13.3c-0.1-0.2-0.1-0.2-0.1-0.3L28,13.3L28,13.3z M30.7,16.8
	L30.7,16.8c0-0.5-0.1-1-0.3-1.4s-0.4-0.7-0.8-0.9c-0.5-0.3-1.1-0.5-1.7-0.6c0,0.3,0,0.6,0,0.9c-0.1,0.6-0.2,1-0.3,1.4
	c0,0.1-0.1,0.3-0.1,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.4-0.2,0.8-0.6,0.8c-0.1,0-0.1,0-0.2,0c-0.3,0.5-0.6,1-0.9,1.4
	c-0.2,0.2-0.3,0.4-0.5,0.6c0.5,0.1,1.1,0.1,1.6,0.1C30,20.1,30.8,18.6,30.7,16.8z"
                  />
                </svg>
              </a>
            </div>
            <div class="text-align-left">
              <p>
                Scouts en Gidsen Vlaanderen vzw <br />
                Wilrijkstraat 45, 2140 Antwerpen <br />
                RPR Antwerpen - 0407.138.494 <br />
                <a
                  href="mailto:info@scoutsengidsenvlaanderen.be"
                  class="clean-link text-white"
                  >info@scoutsengidsenvlaanderen.be</a
                >
                <br />
                <a href="tel:+32 3 231 16 20" class="clean-link text-white"
                  >+32 03 231 16 20</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div
            class="footer-partner-block mb-4 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-lg-end mt-1 mt-lg-4"
          >
            <div class="footer-partner mr-md-4">
              <a
                href="https://hopper.be"
                aria-label="Hopper"
                rel="noopener"
                target="_blank"
              >
                <img
                  :src="`${publicPath}static/img/hopper_logo_wit_liggend.png`"
                  class="img-fluid"
                  alt="hopper"
                />
              </a>
            </div>
            <div class="footer-partner">
              <a
                href="https://www.vlaanderen.be/nl"
                aria-label="Vlaanderen"
                rel="noopener"
                target="_blank"
              >
                <img
                  :src="`${publicPath}static/img/vlaanderen-verbeelding-werkt.png`"
                  class="img-fluid vlaanderen"
                  alt="vlaanderen"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 d-flex justify-content-lg-end">
          <small class="ml-3">Versie: {{ app_version || "0" }}</small>
        </div>
      </div>
      <div class="ml-0 small text-white row">
        <div class="col-12 col-md-6 d-flex justify-content-md-end order-md-2">
          <a
            class="clean-link text-white"
            href="https://www.scoutsengidsenvlaanderen.be/privacy"
            target="_blank"
            >Privacyverklaring</a
          >
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-md-start order-md-1">
          © {{ year }} Scouts en Gidsen Vlaanderen vzw ®
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      app_version: process.env.VUE_APP_VERSION,
      publicPath: process.env.BASE_URL,
      year: new Date().getFullYear(),
    };
  },
};
</script>
