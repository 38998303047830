<template>
  <div v-if="criteria && criteria.activated" v-click-outside="close">
    <div class="col-12 type-select-button kolom-select cursor-pointer" @click="toggleMenu = !toggleMenu">
      <div class="row mt--05">
        <div class="col-10 ">
          <div class="text-align-left d-flex">
            <div>
              <label>{{ criteria.title }}:</label>
            </div>
            <div class="row">
              <label class="subtitle cursor-pointer text-align-left criteria-label cut-off-text-filter">{{ label }}</label>
            </div>
          </div>
        </div>
        <div class="col-2">
          <verwijder-criteria :criteria="criteria" />
        </div>
      </div>
    </div>
    <div class="position-absolute z999 bg-white col-11 col-sm-6 col-md-4 col-lg-3 col-xl-2 filter-border filter-height-select" v-if="toggleMenu">
      <div class="d-flex align-content-start">
        <checkbox
          id="alle"
          v-model="selecteerAlles"
          @change="$event.stopPropagation();
                   selecteerAlleGroepen()"
          :binary="true"/>
        <label for="alle" class="ml-3 text-align-left">Selecteer alle groepen</label>
      </div>
      <Divider/>
      <div v-for="( item, index ) in criteria.items" :key="index" class="d-flex align-content-start">
        <checkbox
          :id="index"
          v-model="selectedOptions"
          :value="item.value"
          @change="$event.stopPropagation();
                   checkSelectedOption()"
        />
        <label :for="index" class="ml-3 text-align-left">{{ item.label }} </label>
      </div>
    </div>
  </div>
</template>

<script>

import VerwijderCriteria from "@/components/buttons/VerwijderCriteria";

export default {
  name: "GroepenSelect",
  components: {
    VerwijderCriteria
  },
  data() {
    return {
      toggleMenu: false,
      selecteerAlles: false,
      selectedOptions: [],
    }
  },
  props: {
    criteria: {
      type: Array
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {

    close() {
      this.toggleMenu = false;
    },

    checkSelectedOption() {
      this.selecteerAlles = this.selectedOptions.length === this.criteria.items.length;
      if (this.selecteerAlles) {
        this.criteria.items.forEach((item) => {
          this.selectedOptions.push(item.value);
        })
      }
      this.emitter.emit('changeGroepCriterium', {'criteria': this.criteria, 'selectedOptions': this.selectedOptions})
    },

    selecteerAlleGroepen() {
      if (this.selecteerAlles) {
        this.criteria.items.forEach((item) => {
          this.selectedOptions.push(item.value);
        })
      } else {
        this.selectedOptions = [];
      }
      this.emitter.emit('changeGroepCriterium', {'criteria': this.criteria, 'selectedOptions': this.selectedOptions})
    },
  },

  mounted() {
    if (this.criteria) {
      this.criteria.items.forEach((item) => {
        if (item.activated) {
          this.selectedOptions.push(item.value);
        }
      })
      if (this.criteria.items.length === this.selectedOptions.length) {
        this.selecteerAlles = true;
      }
    }

    if (this.criteria.value) {
      this.selectedOptions = this.criteria.value;
    }

    if (this.criteria.items.length === this.selectedOptions.length) {
      this.selecteerAlles = true;
    }
  },
  computed: {
    label() {
      if (this.criteria && this.selectedOptions && this.selectedOptions.length > 0) {
        let label = "";
        let counter = 0;
        this.criteria.items.forEach((item) => {
          this.selectedOptions.forEach((value) => {
            counter++;
            if (item.value === value) {
              label += item.label;
              if (counter !== this.selectedOptions.length) {
                label += ", "
              }
            }
          })
        })
        return label;
      }
      return "";
    }
  }

}
</script>

<style scoped>

</style>
